import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: "03f5662f-48eb-4b65-b6e9-de71545a4721",
  clientToken: "pub722e15662c55f4011a7df5395588df22",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.eu",
  service: "customer-frontend",
  env: ["prod.aureaimaging.com", "app.aureaimaging.com"].includes(location.host)
    ? "prod"
    : "dev",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 1,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});
